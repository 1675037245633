import * as React from 'react'
import { graphql } from 'gatsby'
import { Stack } from '@chakra-ui/layout'
import { v4 } from 'uuid'

import Layout from '../components/layout'
import { H1, H2 } from '../components/globals'
import Article from '../components/article'
import Section from '../components/section'
import PostList, { PostItem } from '../components/postList'
import Img from '../components/img'
import Seo from '../components/seo'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Todos los posts" />
      <Article>
        <Section
          width="100%"
          maxWidth="100%"
          backgroundColor="primary.500"
          padding={{ base: 4, md: 10 }}
        >
          <Stack
            spacing={1}
            width={{ base: '100%' }}
            textAlign="center"
            marginX="auto"
            color="white"
            paddingX={4}
          >
            <H1>Descubre los beneficios de la marihuana</H1>
            <H2 color="white">Blog cannábico sobre beneficios de la marihuana medicinal.</H2>
          </Stack>
        </Section>

        <Section title="Últimos posts">
          <PostList>
            {posts?.map((post) => {
              return (
                <PostItem
                  key={v4()}
                  to={post.frontmatter.slug}
                  title={post.frontmatter.title}
                  image={
                    <Img
                      fixed={post.frontmatter.featuredImage.childImageSharp.fixed}
                      layout="background"
                    />
                  }
                  desc={post.frontmatter.subtitle}
                />
              )
            })}
          </PostList>
        </Section>
      </Article>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          subtitle
          description
          featuredImage {
            childImageSharp {
              fixed(width: 1024, quality: 65) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
