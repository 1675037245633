import React from 'react'
import { Link } from 'gatsby'

import { Stack, Box, SimpleGrid } from '@chakra-ui/react'

import { H3, P } from '../components/globals'

export function PostItem({ title, to, desc, image, ...restProps }) {
  return (
    <Link to={to}>
      <Stack width="100%" {...restProps}>
        <Box position="relative" paddingBottom="66%" overflow="hidden" marginBottom={1}>
          {image}
        </Box>
        <H3>{title}</H3>
        <P fontSize="xl">{desc}</P>
      </Stack>
    </Link>
  )
}

function PostList({ children }) {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} width="100%">
      {children}
    </SimpleGrid>
  )
}

export default PostList
